<template>
    <div>
        <h3>Комментарии законопроекта</h3>
      <form class="filters" @submit.prevent="emailMarket">
        <div>
          <tags @dataTags="dataTags" :startValue="getEmailMarket" placeholder="введите почтовые адреса через запятую" />
        </div>
        <div class="wrapper-btn-filters">
          <input class="btn-blue" type="submit" value="Сохранить">
        </div>
      </form>
        <article class="accordion-block" :class="{'active': indexElement===i && show}"
            v-for="(item, i) in getAllBill" :key="`post${i}`">
            <header @click="toggle(item, i)">
            <p>{{ item.title }}</p>
            <i class="accordion-block__details" :class="{'active' : indexElement===i && show}"></i>
            </header>
            <transition name="accordion">
            <div class="accordion-block__body" v-show="indexElement===i && show">
                <table>
                    <tr>
                        <td>Заголовок</td>
                        <td>Описание</td>
                        <td>Статус</td>
                        <td class="drop-icon"></td>
                    </tr>
                    <tr v-for="(comment, i) of getAllCommentsBill" :key="'comment'+i">
                        <td>{{ comment.title }}</td>
                        <td v-html="comment.text"></td>
                        <td>{{ comment.status}}</td>
                        <td class="drop-icon">
                        <drop-list @clickItem="clickItem"
                                    :id-item="comment.id"
                                    :edit-link="`comment_bill/${comment.id}/edit`"
                                    />
                        </td>
                    </tr>
                </table>
                <router-link class="btn-blue" :to="`comment_bill/${item.id}/create`">
                    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="9" fill="#DDEDFE"/>
                    <rect x="3" y="10" width="2" height="12" rx="1" transform="rotate(-90 3 10)" fill="#246CB7"/>
                    <rect x="10" y="15" width="2" height="12" rx="1" transform="rotate(180 10 15)" fill="#246CB7"/>
                    </svg>
                    Добавить комментарий
                </router-link>
            </div>
            </transition>
        </article>
    </div>
</template>
<script>
import DropList from '@/components/Admin/DropList'
import Tags from '@/components/Admin/Tags'
export default {
  name: 'CommentsBill',
  data () {
    return {
      emails: [],
      idMember: '',
      show: false,
      indexElement: 0
    }
  },

  created () {
    this.$store.dispatch('setAllBill')
  },

  methods: {
    toggle (item, index) {
      if (this.indexElement === index && this.show === true) {
        this.show = false
      } else {
        this.idMember = item.id
        this.$store.dispatch('setAllCommentsBill', item.id)
        this.show = true
      }
      this.indexElement = index
    },
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить комментарий?')
        if (consent) {
          this.$store.dispatch('deleteCommentBill', value.id)
          this.show = false
        } else {
          this.$noty.warning('Удаление отменено')
        }
      }
    },
    dataTags (value) {
      this.emails = value
    },
    emailMarket () {
      this.$store.dispatch('updateEmailMarket', { title: 'Комментарии к законопроектам', emails: this.emails })
    }
  },

  computed: {
    getAllBill () {
      return this.$store.getters.getAllBill
    },
    getAllCommentsBill () {
      return this.$store.getters.getAllCommentsBill
    },
    getEmailMarket () {
      return this.$store.getters.getEmailMarket
    }
  },
  async mounted () {
    await this.$store.dispatch('setEmailMarket', 'Комментарии к законопроектам')
  },
  watch: {
    // getAllCommentsBill () {
    //   this.show = false
    // }
  },
  components: {
    DropList,
    Tags
  }
}
</script>
<style lang="scss" scoped>
h3 {
    font-weight: 600;
    font-size: 1.37rem;
    color: #1F3245;
    margin-bottom: 3.1rem;
  }
.blue{
    margin-bottom: 2rem;
}
.filters{
  margin-top: 1.37rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #D5D6D9;
  margin-bottom: 2rem;
}
.accordion-block {
    border: .06rem solid #E5E8EE;
    border-radius: 0.5rem;
    margin-bottom: 1.875rem;
    font-size: .875rem;
    line-height: 1.57;

    &.active {
      box-shadow: 0 .94rem 1.56rem rgba(189, 205, 221, 0.35);
    }

    header {
      color: #1F3245;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.25rem 1.875rem;

      & p { margin-right: 5rem; }
      & i {
        border: .1875rem solid #9D9D9D;
        border-width: 0 .1875rem .1875rem 0;
        border-radius: .0625rem;
        display: inline-block;
        padding: .1875rem;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin-bottom: .0625rem;
      }
      .active {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
    }
  }
.content-sb{
    margin-bottom: 2rem;
}
.accordion-block__body{
    padding: 0 1.875rem;
}
  table{
    margin-top: 1rem;
    border-spacing: 0;
    border-collapse: collapse;

    tr{
      padding: 0;
      border-bottom: 1px solid #D5D6D9;

      td {
        max-width: 23%;
        font-size: .812rem;
        line-height: 154%;
        color: #1F3245;
        padding: 1.375rem 1.375rem 1.375rem 0;
        a {
          color: #246CB7;
        }
        img {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
        }
      }
      &:first-of-type td{
        padding: 1rem 0;
        color: #5E686C;
      }
    }
  }
table:last-child tr{
    border-bottom: 0;
}
.btn-blue {
  display: flex;
  justify-content: center;
  width: 17rem;
  margin: 1rem 0;

  svg {
    width: 1.125rem;
    margin-right: 0.56rem;
  }
}
@media screen and (max-width: 768px){
}
@media screen and (max-width: 420px){
  .accordion-block{
    header{
      padding: 1.25rem 1.38rem;
      p{
        margin-right: .125rem;
      }
    }
  }
}
</style>
